<template>
  <div class="page-wrap">
    <div class="content">
      <div class="img-wrap">
        <img v-for="(img, i) in pdfImages" :key="i" :src="img" />
      </div>
    </div>
    <div v-if="showBtn" class="btns">
      <van-button type="info" size="small" @click="goBack"
        >返回上一页</van-button
      >
    </div>
  </div>
</template>

<script>
import * as PDFJS from "pdfjs-dist";
let pdfjsWorker = require("pdfjs-dist/build/pdf.worker.min");

PDFJS.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  name: "HomeIndex",
  data() {
    return {
      ossUrl: "", // pdf 地址
      pdfLoading: false,
      pdfImages: [],
      showBtn: true
    };
  },
  watch: {
    pdfLoading(v) {
      if (v) {
        this.$toast.loading({
          message: "加载中...",
          forbidClick: true,
          loadingType: "circular"
        });
      } else this.$toast.clear();
    }
  },
  mounted() {
    this.getPdfUrl();
  },
  methods: {
    // 获取pdf
    async getPdfUrl() {
      try {
        document.title = this.$route.query.title || "";
        this.ossUrl = decodeURIComponent(this.$route.query.url);

        this.pdfImages = await this.loadPDFSetImages();
        console.log(" this.$route.query.showBtn ", this.$route.query.showBtn);

        this.showBtn = this.$route.query.showBtn == false;
      } catch (error) {
        this.$errMsg(error);
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    // load pdf to images
    async loadPDFSetImages() {
      try {
        let url = this.ossUrl;
        if (!url) throw "地址错误";
        this.pdfLoading = true;
        return new Promise((resp, rej) => {
          let self = this;
          self.pdfPicturePath = [];
          let arr = [];
          try {
            const loadingTask = PDFJS.getDocument(url);
            loadingTask.promise
              .then((pdf) => {
                if (pdf) {
                  // pdf 总页数
                  const pageNum = pdf.numPages;
                  for (let i = 1; i <= pageNum; i++) {
                    pdf.getPage(i).then((page) => {
                      const canvas = document.createElement("canvas");
                      const ctx = canvas.getContext("2d");
                      const viewport = page.getViewport({ scale: 2 });
                      canvas.height = viewport.height;
                      canvas.width = viewport.width;
                      const destWidth = 750;
                      canvas.style.width = destWidth + "px";
                      canvas.style.height =
                        destWidth * (viewport.height / viewport.width) + "px";
                      page.render({
                        canvasContext: ctx,
                        viewport
                      });
                      setTimeout(() => {
                        let url = canvas.toDataURL("image/png");
                        arr.push(url);
                        if (i === pageNum) {
                          self.pdfLoading = false;
                          resp(arr);
                        }
                      }, 1000);
                    });
                  }
                }
              })
              .catch((e) => {
                console.error("Error: " + e.message || e);
                self.pdfLoading = false;
                rej("Error: " + e.message || e);
              });
          } catch (error) {
            console.error("Error: " + error.message || error);
            self.pdfLoading = false;
            rej("Error: " + error.message || error);
          }
        });
      } catch (error) {
        return Promise.reject(error.message || error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.page-wrap {
  overflow-y: auto;
  .content,
  .img-wrap,
  .img-wrap > img {
    width: 100%;
  }
  .btns {
    width: 100vw;
    position: fixed;
    bottom: 0px;
    left: 0px;
    z-index: 100;
    border-top: 1px solid #efefef;
    height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 -5px 10px #efefef;
    .van-button {
      width: 90%;
      height: 75%;
    }
  }
}
</style>
